<template>
    <v-container>
        <!--System Settings-->
        <v-card v-if="isInRole(71)" :loading="loading" class="mb-5">
            <Page-Header :title="$t('settings.systemSettings')" />
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-text-field
                            outlined
                            hide-details
                            dense
                            v-model="systemSetting.name"
                            :label="$t('settings.ownerPlaceName')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3"
                        ><v-text-field
                            outlined
                            hide-details
                            dense
                            v-model="systemSetting.governorate"
                            :label="$t('settings.governorate')"
                        ></v-text-field
                    ></v-col>
                    <v-col cols="12" md="3"
                        ><v-text-field
                            outlined
                            hide-details
                            dense
                            v-model="systemSetting.address"
                            :label="$t('settings.address')"
                        ></v-text-field
                    ></v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            outlined
                            hide-details
                            dense
                            v-model="systemSetting.phone"
                            :label="$t('settings.phone')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6"
                        ><v-text-field
                            outlined
                            hide-details
                            dense
                            v-model="systemSetting.email"
                            :label="$t('settings.email')"
                        ></v-text-field
                    ></v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            outlined
                            hide-details
                            dense
                            v-model="systemSetting.website"
                            :label="$t('settings.website')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            outlined
                            hide-details
                            dense
                            v-model="systemSetting.description"
                            :label="$t('settings.description')"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        v-if="
                            systemLogos != undefined &&
                                (systemLogos != null) & (systemLogos.length > 0)
                        "
                        cols="12"
                        md="6"
                    >
                        <v-row>
                            <v-col cols="12"
                                ><p class="black--text">
                                    {{ $t("settings.image") }}
                                </p></v-col
                            >
                            <v-col cols="12">
                                <v-card
                                    class="overflow-y-auto"
                                    max-height="300"
                                    elevation="0"
                                >
                                    <v-card-text>
                                        <v-row>
                                            <v-col
                                                v-for="file in systemLogos"
                                                :key="file.name"
                                                cols="12"
                                                md="12"
                                            >
                                                <Download-File
                                                    :iconWidth="50"
                                                    :fileSizeFormat="file.size"
                                                    :onDelete="deleteFile"
                                                    :onClose="closeDeleteFile"
                                                    :file="file"
                                                    :fileUrl="file.path"
                                                ></Download-File>
                                            </v-col> </v-row
                                    ></v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-file-input
                            v-model="currFiles"
                            small-chips
                            show-size
                            :label="$t('settings.image')"
                            multiple
                            hide-details
                            @click:clear="clearFiles"
                            @change="inputChanged"
                            v-if="systemLogos.length == 0"
                        >
                            <template
                                v-slot:selection="{
                                    text
                                }"
                            >
                                <v-chip
                                    small
                                    text-color="white"
                                    color="#295671"
                                >
                                    {{ text }}
                                </v-chip>
                            </template>
                        </v-file-input>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    v-if="isInRole(28)"
                    class="mx-3"
                    color="primary darken-1 white--text"
                    @click="saveSystemSettings"
                    :disabled="!valid"
                    :min-width="100"
                    :loading="loading"
                >
                    <v-icon>mdi-content-save-outline</v-icon>
                    {{ $t("save") }}
                </v-btn>
            </v-card-actions>
            <!--Delete file confirm-->
            <confirm-dialog
                :openDialog="deleteFileDialog"
                :onClicked="deleteFileConfirm"
                :onClose="closeDeleteFile"
                toolBarColor="red darken-2"
            ></confirm-dialog>
        </v-card>
        <!--Default Accounts Settings-->
        <v-form v-if="isInRole(73)" ref="form" v-model="valid" lazy-validation>
            <v-card :loading="loading" class="mb-5">
                <Page-Header :title="$t('settings.DefaultAccounts')" />
                <v-card-text>
                    <v-row justify="center">
                        <v-col cols="12" md="3">
                            <v-autocomplete
                                :label="$t('settings.ClientAccount')"
                                v-model="clientAccount"
                                outlined
                                hide-details
                                dense
                                hide-selected
                                :items="accounts"
                                item-text="nameCode"
                                item-value="accountGuid"
                                :rules="rules"
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-autocomplete
                                :label="$t('settings.SupplierAccount')"
                                v-model="supplierAccount"
                                outlined
                                dense
                                hide-details
                                hide-selected
                                :items="accounts"
                                item-text="nameCode"
                                item-value="accountGuid"
                                :rules="rules"
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-autocomplete
                                :label="$t('settings.BothAccount')"
                                v-model="bothAccount"
                                outlined
                                dense
                                hide-details
                                hide-selected
                                :items="accounts"
                                item-text="nameCode"
                                item-value="accountGuid"
                                :rules="rules"
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col v-if="isInRole(43)" cols="12" md="3">
                            <v-autocomplete
                                :label="$t('settings.StudentAccount')"
                                v-model="studentAccount"
                                outlined
                                hide-details
                                dense
                                hide-selected
                                :items="accounts"
                                item-text="nameCode"
                                item-value="accountGuid"
                                :rules="rules"
                            >
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        v-if="isInRole(28)"
                        class="mx-3"
                        color="primary darken-1 white--text"
                        @click="save"
                        :disabled="!valid"
                        :min-width="100"
                        :loading="loading"
                    >
                        <v-icon>mdi-content-save-outline</v-icon>
                        {{ $t("save") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
        <!--Voucher Settings-->
        <v-form
            v-if="isInRole(75)"
            ref="vouchersForm"
            v-model="valid"
            lazy-validation
        >
            <v-card class="mb-5">
                <Page-Header :title="$t('voucherSettings.vouchersSettings')" />

                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="3">
                                <v-autocomplete
                                    :label="
                                        $t(
                                            'voucherSettings.paymentVoucherAccount'
                                        )
                                    "
                                    v-model="paymentVoucherAccount"
                                    outlined
                                    hide-details
                                    dense
                                    hide-selected
                                    :items="accounts"
                                    item-text="nameCode"
                                    item-value="accountGuid"
                                    :rules="rules"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-autocomplete
                                    :label="
                                        $t(
                                            'voucherSettings.paymentVoucherCurrency'
                                        )
                                    "
                                    outlined
                                    dense
                                    hide-details
                                    v-model="paymentVoucherCurrency"
                                    hide-selected
                                    :items="currencies"
                                    item-text="currencySymbol"
                                    item-value="currencyGuid"
                                    :rules="rules"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-autocomplete
                                    :label="
                                        $t(
                                            'voucherSettings.receiptVoucherAccount'
                                        )
                                    "
                                    v-model="receiptVoucherAccount"
                                    outlined
                                    dense
                                    hide-details
                                    hide-selected
                                    :items="accounts"
                                    item-text="nameCode"
                                    item-value="accountGuid"
                                    :rules="rules"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-autocomplete
                                    :label="
                                        $t(
                                            'voucherSettings.receiptVoucherCurrency'
                                        )
                                    "
                                    outlined
                                    v-model="receiptVoucherCurrency"
                                    dense
                                    hide-details
                                    hide-selected
                                    :items="currencies"
                                    item-text="currencySymbol"
                                    item-value="currencyGuid"
                                    :rules="rules"
                                >
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        v-if="isInRole(28)"
                        class="mx-3"
                        :loading="loading"
                        color="primary darken-1 white--text"
                        @click="saveVouchers"
                        :disabled="!valid"
                        :min-width="100"
                    >
                        <v-icon>mdi-content-save-outline</v-icon>
                        {{ $t("save") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
        <!--Bill Settings-->
        <v-form
            v-if="isInRole(72)"
            ref="billsForm"
            v-model="valid"
            lazy-validation
        >
            <v-card class="mb-5">
                <Page-Header :title="$t('settings.Bill')" />
                <v-card-text class="pa-7">
                    <v-row
                        v-for="setting in billSettings"
                        :key="setting.billSettingGuid"
                        class="my-border"
                    >
                        <v-col cols="12" md="12">
                            {{ $t("billTypes." + setting.billType) }}
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-autocomplete
                                :label="$t('account')"
                                v-model="setting.accountGuid"
                                outlined
                                dense
                                hide-details
                                hide-selected
                                :items="accounts"
                                item-text="nameCode"
                                item-value="accountGuid"
                                :rules="rules"
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-autocomplete
                                :label="$t('accountContra')"
                                v-model="setting.accountContraGuid"
                                outlined
                                hide-details
                                dense
                                hide-selected
                                :items="accounts"
                                item-text="nameCode"
                                item-value="accountGuid"
                                :rules="rules"
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-autocomplete
                                :label="$t('customer.customer')"
                                outlined
                                dense
                                hide-details
                                v-model="setting.customerGuid"
                                hide-selected
                                :items="customers"
                                item-text="fullName"
                                item-value="customerGuid"
                                :rules="rules"
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-autocomplete
                                :label="$t('stock.stock')"
                                outlined
                                dense
                                hide-details
                                v-model="setting.stockGuid"
                                hide-selected
                                :items="stocks"
                                item-text="stockName"
                                item-value="stockGuid"
                                :rules="rules"
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-autocomplete
                                :label="$t('currency.currency')"
                                outlined
                                hide-details
                                dense
                                v-model="setting.currencyGuid"
                                hide-selected
                                :items="currencies"
                                item-text="currencySymbol"
                                item-value="currencyGuid"
                                :rules="rules"
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col
                            v-if="setting.billType == 'InstallmentsSale'"
                            cols="12"
                            md="4"
                        >
                            <v-select
                                outlined
                                hide-details
                                dense
                                persistent-hint
                                v-model="setting.installmentType"
                                :rules="rules"
                                :items="installmentTypes"
                                item-text="text"
                                item-value="value"
                                :label="$t('installments.installmentType')"
                            ></v-select>
                        </v-col>
                        <v-col
                            v-if="setting.billType == 'InstallmentsSale'"
                            cols="12"
                            md="4"
                        >
                            <v-text-field
                                outlined
                                type="number"
                                min="0"
                                dense
                                v-model="setting.paymentIncrement"
                                :rules="rules"
                                :label="$t('installments.paymentIncrement')"
                                hide-details
                            ></v-text-field>
                        </v-col>
                        <v-col
                            v-if="setting.billType == 'InstallmentsSale'"
                            cols="12"
                            md="4"
                        >
                            <v-text-field
                                outlined
                                type="number"
                                dense
                                :rules="rules"
                                v-model="setting.paymentNumber"
                                :label="$t('installments.paymentCount')"
                                hide-details
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-select
                                outlined
                                hide-details
                                dense
                                persistent-hint
                                v-model="setting.priceType"
                                :rules="rules"
                                :items="priceTypes"
                                item-text="key"
                                item-value="value"
                                :label="$t('bill.priceType')"
                            ></v-select>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        v-if="isInRole(28)"
                        class="mx-3"
                        :loading="loading"
                        color="primary darken-1 white--text"
                        @click="saveBills"
                        :disabled="!valid"
                        :min-width="100"
                    >
                        <v-icon>mdi-content-save-outline</v-icon>
                        {{ $t("save") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-container>
</template>
<script>
import axios from "axios";
import DownloadFile from "../../components/downloadFile.vue";
import PageHeader from "../../components/PageHeader.vue";
import ConfirmDialog from "../../components/ConfirmDialog.vue";

export default {
    components: { DownloadFile, ConfirmDialog, PageHeader },
    data() {
        return {
            deletedFile: null,
            deleteFileDialog: false,
            systemLogos: [],
            currFiles: [],
            files: [],
            systemSetting: {
                name: null,
                phone: null,
                description: null,
                address: null,
                email: null,
                image: null,
                website: null
            },
            systemSettings: [],
            defaultAccounts: [],
            customers: [],
            stocks: [],
            voucherSettings: [],
            billSettings: [],
            currencies: [],
            accounts: [],
            paymentVoucherAccount: null,
            paymentVoucherCurrency: null,
            receiptVoucherAccount: null,
            receiptVoucherCurrency: null,
            clientAccount: null,
            supplierAccount: null,
            bothAccount: null,
            studentAccount: null,
            activePicker: null,
            date: null,
            menu: false,
            valid: true,
            loading: true,
            dialogDelete: false,
            editedIndex: -1,
            installmentTypes: [
                {
                    text: this.$t("installmentTypes.daily"),
                    value: 1
                },
                {
                    text: this.$t("installmentTypes.weekly"),
                    value: 2
                },
                {
                    text: this.$t("installmentTypes.monthly"),
                    value: 3
                },
                {
                    text: this.$t("installmentTypes.yearly"),
                    value: 4
                }
            ],
            priceTypes: [
                { key: this.$t("priceTypes.0"), value: 0 },
                { key: this.$t("priceTypes.1"), value: 1 },
                { key: this.$t("priceTypes.2"), value: 2 },
                { key: this.$t("priceTypes.3"), value: 3 },
                { key: this.$t("priceTypes.4"), value: 4 },
                { key: this.$t("priceTypes.5"), value: 5 }
            ],
            rules: [value => !!value || value == 0 || this.$t("required")]
        };
    },
    created() {
        this.loading = true;
        this.getData();
    },
    methods: {
        getData() {
            try {
                this.loading = true;
                axios
                    .get("DatabaseSettings/Get")
                    .then(response => {
                        var data = response.data.data.systemSettings;
                        this.systemLogos = response.data.data.files;

                        //defalut accounts section
                        this.defaultAccounts = data.filter(
                            x => x.group == "DefaultAccounts"
                        );

                        this.clientAccount = this.defaultAccounts.find(
                            x => x.key == "Client"
                        ).value;

                        this.supplierAccount = this.defaultAccounts.find(
                            x => x.key == "Supplier"
                        ).value;

                        this.bothAccount = this.defaultAccounts.find(
                            x => x.key == "Both"
                        ).value;

                        this.studentAccount = this.defaultAccounts.find(
                            x => x.key == "Student"
                        ).value;

                        //system settings section
                        this.systemSettings = data.filter(
                            x => x.group == "OwnerSettings"
                        );

                        this.systemSetting.name = this.systemSettings.find(
                            x => x.key == "Name"
                        ).value;

                        this.systemSetting.phone = this.systemSettings.find(
                            x => x.key == "Phone"
                        ).value;

                        this.systemSetting.description = this.systemSettings.find(
                            x => x.key == "Description"
                        ).value;

                        this.systemSetting.address = this.systemSettings.find(
                            x => x.key == "Address"
                        ).value;

                        this.systemSetting.email = this.systemSettings.find(
                            x => x.key == "Email"
                        ).value;

                        this.systemSetting.image = this.systemSettings.find(
                            x => x.key == "Image"
                        ).value;

                        this.systemSetting.website = this.systemSettings.find(
                            x => x.key == "Website"
                        ).value;

                        this.systemSetting.governorate = this.systemSettings.find(
                            x => x.key == "Governorate"
                        ).value;
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        console.log(e);
                    })
                    .finally(() => {
                        this.loading = false;
                    });

                this.loading = true;
                axios
                    .get("DatabaseSettings/GetInitialData")
                    .then(response => {
                        this.accounts = response.data.data.accounts;
                        this.currencies = response.data.data.currencies;
                        this.customers = response.data.data.customers;
                        this.stocks = response.data.data.stocks;

                        this.billSettings = response.data.data.billSettings;
                        if (this.isInRole(43)) {
                            this.billSettings = this.billSettings.filter(
                                function(obj) {
                                    return obj.billType == "InstallmentsSale";
                                }
                            );
                        }

                        this.voucherSettings =
                            response.data.data.voucherSettings;
                        this.paymentVoucherAccount = this.voucherSettings.filter(
                            function(obj) {
                                return obj.type == 3;
                            }
                        )[0].accountGuid;

                        this.paymentVoucherCurrency = this.voucherSettings.filter(
                            function(obj) {
                                return obj.type == 3;
                            }
                        )[0].currencyGuid;

                        this.receiptVoucherAccount = this.voucherSettings.filter(
                            function(obj) {
                                return obj.type == 2;
                            }
                        )[0].accountGuid;

                        this.receiptVoucherCurrency = this.voucherSettings.filter(
                            function(obj) {
                                return obj.type == 2;
                            }
                        )[0].currencyGuid;
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        console.log(e);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } catch (error) {
                console.log(error);
            }
        },
        save() {
            if (this.$refs.form.validate()) {
                var clientAccount = this.clientAccount;
                var supplierAccount = this.supplierAccount;
                var bothAccount = this.bothAccount;
                var studentAccount = this.studentAccount;

                this.defaultAccounts.forEach(function(obj) {
                    if (obj.key == "Client") {
                        obj.value = clientAccount;
                    } else if (obj.key == "Supplier") {
                        obj.value = supplierAccount;
                    } else if (obj.key == "Both") {
                        obj.value = bothAccount;
                    } else if (obj.key == "Student") {
                        obj.value = studentAccount;
                    }
                });

                axios
                    .post("DatabaseSettings/SaveRange", this.defaultAccounts)
                    .then(response => {
                        if (response.data.status == "Successful") {
                            this.$toast.success(
                                this.$t("operationAccomplishedSuccessfully")
                            );
                        } else {
                            this.$toast.error(
                                this.$t("error." + response.data.message)
                            );
                        }
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        console.log(e);
                    })
                    .finally(() => {
                        this.loading = false;
                        this.getData();
                    });
            }
        },
        saveSystemSettings() {
            try {
                var name = this.systemSetting.name;
                var phone = this.systemSetting.phone;
                var description = this.systemSetting.description;
                var address = this.systemSetting.address;
                var email = this.systemSetting.email;
                var image = this.systemSetting.image;
                var website = this.systemSetting.website;
                var governorate = this.systemSetting.governorate;

                this.systemSettings.forEach(function(obj) {
                    if (obj.key == "Name") {
                        obj.value = name;
                    } else if (obj.key == "Phone") {
                        obj.value = phone;
                    } else if (obj.key == "Description") {
                        obj.value = description;
                    } else if (obj.key == "Address") {
                        obj.value = address;
                    } else if (obj.key == "Email") {
                        obj.value = email;
                    } else if (obj.key == "Image") {
                        obj.value = image;
                    } else if (obj.key == "Website") {
                        obj.value = website;
                    } else if (obj.key == "Governorate") {
                        obj.value = governorate;
                    }
                });

                var data = new FormData();

                for (let i = 0; i < this.files.length; i++) {
                    var file = this.files[i];
                    if (file != null && file.size <= 2097152) {
                        data.append("files", file);
                        this.systemSettings.forEach(function(obj) {
                            if (obj.key == "Image") {
                                obj.value = file.name;
                            }
                        });
                    } else {
                        this.$toast.error(
                            this.$t("error.FileSizeMustBeLessThan2MegaBytes")
                        );
                        return;
                    }
                }

                data.append(
                    "systemSettings",
                    JSON.stringify(this.systemSettings)
                );

                axios
                    .post("DatabaseSettings/SaveSystemSettings", data)
                    .then(response => {
                        if (response.data.status == "Successful") {
                            this.$toast.success(
                                this.$t("operationAccomplishedSuccessfully")
                            );
                            this.files = [];
                            this.currFiles = [];
                        } else {
                            this.$toast.error(
                                this.$t("error." + response.data.message)
                            );
                        }
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        console.log(e);
                    })
                    .finally(() => {
                        this.loading = false;
                        this.getData();
                    });
            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false;
                this.getData();
            }
        },
        saveBills() {
            if (this.$refs.billsForm.validate()) {
                axios
                    .post("Bills/UpdateBillSettings", this.billSettings)
                    .then(response => {
                        if (response.data.status == "Successful") {
                            this.$toast.success(
                                this.$t("operationAccomplishedSuccessfully")
                            );
                        } else {
                            this.$toast.error(
                                this.$t("error." + response.data.message)
                            );
                        }
                        this.getData();
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        this.getData();
                        console.log(e);
                    });
            }
        },
        saveVouchers() {
            if (this.$refs.vouchersForm.validate()) {
                //Vouchers Settings
                for (let i = 0; i < this.voucherSettings.length; i++) {
                    if (this.voucherSettings[i].type == 3) {
                        this.voucherSettings[
                            i
                        ].accountGuid = this.paymentVoucherAccount;

                        this.voucherSettings[
                            i
                        ].currencyGuid = this.paymentVoucherCurrency;
                    } else if (this.voucherSettings[i].type == 2) {
                        this.voucherSettings[
                            i
                        ].accountGuid = this.receiptVoucherAccount;

                        this.voucherSettings[
                            i
                        ].currencyGuid = this.receiptVoucherCurrency;
                    }
                }

                axios
                    .post(
                        "Vouchers/SaveAllVoucherSettings",
                        this.voucherSettings
                    )
                    .then(response => {
                        if (response.data.status == "Successful") {
                            this.$toast.success(
                                this.$t("operationAccomplishedSuccessfully")
                            );
                        } else {
                            this.$toast.error(
                                this.$t("error." + response.data.message)
                            );
                        }
                        this.getData();
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        this.getData();
                        console.log(e);
                    });
            }
        },
        clearFiles() {
            try {
                this.files = [];
            } catch (error) {
                console.log(error);
            }
        },
        inputChanged() {
            this.files = [...this.currFiles, ...this.files];
        },
        deleteFileConfirm() {
            this.loading = true;
            axios
                .delete(
                    "DatabaseSettings/DeleteSystemImage?fileName=" +
                        this.deletedFile
                )
                .then(response => {
                    if (response.data.status == "Successful") {
                        this.$toast.success(
                            this.$t("operationAccomplishedSuccessfully")
                        );
                        this.deleteFileDialog = false;
                    } else {
                        this.$toast.error(
                            this.$t("error." + response.data.message)
                        );
                    }
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    this.deleteFileDialog = false;
                    console.log(e);
                })
                .finally(() => {
                    this.loading = false;
                    this.getData();
                });
        },
        deleteFile(file) {
            this.deletedFile = file;
            this.deleteFileDialog = true;
        },
        closeDeleteFile() {
            this.deleteFileDialog = false;
            this.currFiles = [];
            this.files = [];
        }
    }
};
</script>
<style>
.my-border {
    border: 1px solid rgb(190, 190, 190);
    margin-bottom: 2px;
    border-radius: 10px;
}
</style>
